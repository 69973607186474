.Chip {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 16px;
  padding-left: 20px;
  margin-left: 8px;
  @apply text-xs;
  line-height: 14px;
}

.Cancelled {
  @apply text-red-500 font-bold;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iOS41IiBjeT0iOS41IiByPSI5LjUiIGZpbGw9IiNGNDQ2NTMiLz4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTUuNjIgNS42MmEuNDIuNDIgMCAwIDEgLjYgMEw5LjUgOC45bDMuMjgtMy4yOGEuNDIuNDIgMCAxIDEgLjYuNkwxMC4xIDkuNWwzLjI4IDMuMjhhLjQyLjQyIDAgMCAxLS42LjZMOS41IDEwLjFsLTMuMjggMy4yOGEuNDIuNDIgMCAxIDEtLjYtLjZMOC45IDkuNSA1LjYyIDYuMjJhLjQyLjQyIDAgMCAxIDAtLjZaIiBmaWxsPSIjZmZmIiBzdHJva2U9IiNmZmYiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K");
}
.Confirmed {
  @apply text-green-600 relative;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjkyIDE5Yy0uMDcgMC0uMTQgMC0uMi0uMDMtLjMyLS4xLTcuNjYtMi40LTcuNjYtOC44MiAwLTEuNzktLjAzLTMuMDgtLjA0LTQuMDMtLjA1LTIuMzctLjA2LTIuODcuNTYtMy40OEMxLjMgMS45MSA2Ljg2IDAgNy45MiAwYzEuMDYgMCA2LjYgMS45IDcuMzQgMi42NC42MS42MS42IDEuMS41NiAzLjQ4LS4wMi45NS0uMDQgMi4yNC0uMDQgNC4wMyAwIDYuNDMtNy4zNSA4LjczLTcuNjYgOC44MmEuNjguNjggMCAwIDEtLjIuMDMiIGZpbGw9IiMyN0FFNjAiLz4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMzUgMTIuNmMtLjI3IDAtLjUyLS4xLS43MS0uMjhMMy4wOSA5Ljg2Yy0uMzktLjM4LS4zOS0xIDAtMS4zOC40LS4zNyAxLjA0LS4zNyAxLjQzIDBsMS44MyAxLjc3IDQuNTMtNC4zNmMuNC0uMzggMS4wMy0uMzggMS40MiAwIC40LjM4LjQgMSAwIDEuMzdsLTUuMjQgNS4wNmMtLjE4LjE4LS40NC4yOC0uNy4yOCIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
}
.RescheduledTba {
  @apply text-orange;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iOS41IiBjeT0iOS41IiByPSI5LjUiIGZpbGw9IiNGRjY5NDYiLz4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuNDIgOC4xNmE1LjI4IDUuMjggMCAwIDEgOS40LTEuNzFsLS42OS0uMThhLjcyLjcyIDAgMSAwLS4zNyAxLjM5bDIuNTUuNjhjLjM5LjEuNzgtLjEyLjg4LS41bC42OS0yLjU2YS43Mi43MiAwIDEgMC0xLjQtLjM3bC0uMjcgMS4wM2E2LjcyIDYuNzIgMCAxIDAtNy40MyAxMC4wOGguMDFsLjY4LjE1YS43Mi43MiAwIDAgMCAuMjMtMS40MyA1LjM2IDUuMzYgMCAwIDEtLjU0LS4xMWgtLjAxYTUuMjggNS4yOCAwIDAgMS0zLjczLTYuNDdabTUuOTMgNi41OGEuNzIuNzIgMCAwIDAgLjIzIDEuNDNjLjQ2LS4wOC45Mi0uMiAxLjM2LS4zN2EuNzIuNzIgMCAxIDAtLjUyLTEuMzRjLS4zNC4xMy0uNy4yMy0xLjA3LjI4Wm0yLjUtMS4xYS43Mi43MiAwIDAgMCAuOSAxLjExYy4zNy0uMy43LS42MyAxLTFhLjcyLjcyIDAgMSAwLTEuMTItLjljLS4yMy4yOS0uNS41NS0uNzguNzhabTEuNi0yLjIyYS43Mi43MiAwIDAgMCAxLjM1LjUxYy4wOS0uMjEuMTYtLjQ0LjIyLS42NmEuNzIuNzIgMCAxIDAtMS40LS4zOGMtLjA0LjE4LS4xLjM2LS4xNi41M1pNMTAuNSA2LjE2YS43Mi43MiAwIDEgMC0xLjQ1IDBWOS41bC0yLjA3IDEuMzVhLjcyLjcyIDAgMSAwIC43OSAxLjIxbDIuNzMtMS43N1Y2LjE2WiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
}
.Tba {
  @apply text-orange;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGNpcmNsZSBjeD0iOS41IiBjeT0iOS41IiByPSI5LjUiIGZpbGw9IiNGRjY5NDYiLz4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTQuNDIgOC4xNmE1LjI4IDUuMjggMCAwIDEgOS40LTEuNzFsLS42OS0uMThhLjcyLjcyIDAgMSAwLS4zNyAxLjM5bDIuNTUuNjhjLjM5LjEuNzgtLjEyLjg4LS41bC42OS0yLjU2YS43Mi43MiAwIDEgMC0xLjQtLjM3bC0uMjcgMS4wM2E2LjcyIDYuNzIgMCAxIDAtNy40MyAxMC4wOGguMDFsLjY4LjE1YS43Mi43MiAwIDAgMCAuMjMtMS40MyA1LjM2IDUuMzYgMCAwIDEtLjU0LS4xMWgtLjAxYTUuMjggNS4yOCAwIDAgMS0zLjczLTYuNDdabTUuOTMgNi41OGEuNzIuNzIgMCAwIDAgLjIzIDEuNDNjLjQ2LS4wOC45Mi0uMiAxLjM2LS4zN2EuNzIuNzIgMCAxIDAtLjUyLTEuMzRjLS4zNC4xMy0uNy4yMy0xLjA3LjI4Wm0yLjUtMS4xYS43Mi43MiAwIDAgMCAuOSAxLjExYy4zNy0uMy43LS42MyAxLTFhLjcyLjcyIDAgMSAwLTEuMTItLjljLS4yMy4yOS0uNS41NS0uNzguNzhabTEuNi0yLjIyYS43Mi43MiAwIDAgMCAxLjM1LjUxYy4wOS0uMjEuMTYtLjQ0LjIyLS42NmEuNzIuNzIgMCAxIDAtMS40LS4zOGMtLjA0LjE4LS4xLjM2LS4xNi41M1pNMTAuNSA2LjE2YS43Mi43MiAwIDEgMC0xLjQ1IDBWOS41bC0yLjA3IDEuMzVhLjcyLjcyIDAgMSAwIC43OSAxLjIxbDIuNzMtMS43N1Y2LjE2WiIgZmlsbD0iI2ZmZiIvPgo8L3N2Zz4K");
}

.StatusText {
  @apply hidden transition-opacity opacity-0 absolute top-1/2 mt-[-12px];
  @apply z-10 rounded-sm;
  @apply text-[13px] text-white bg-green-600 font-bold whitespace-nowrap;
  line-height: 1;
  padding: 6px 8px;
}

.Confirmed:hover .StatusText {
  @apply inline-block opacity-100;
}
